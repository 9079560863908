.auth0-lock.auth0-lock {
  font-size: 14px !important;

  .auth0-lock-center {
    padding: 0 !important;
  }

  .auth0-lock-submit {
    .auth0-label-submit {
      svg {
        display: none;
      }
    }
  }

  .auth0-lock-input-wrap {
    span {
      display: block !important;
    }
  }

  .auth0-lock-tabs li.auth0-lock-tabs-current {
    box-shadow: 0 2px 0 0 #0d1f23 !important;

    span {
      color: #0d1f23 !important;
    }
  }

  .auth0-lock-password-strength {
    background-color: @black-90 !important;
    border-radius: 4px !important;
    position: relative !important;
    top: -48px;
    left: 385px !important;

    ul {
      color: white !important;
      padding: 12px !important;
      li {
        color: white !important;
        margin-top: 0 !important;

        span {
          background-image: none !important;
          padding-left: 0 !important;
          font-family: "Axiforma-Medium", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .auth0-lock-password-strength:after {
    border-color: transparent @black-90 transparent transparent !important;
    top: 35% !important;
    right: 100% !important;
    left: -8px !important;
  }

  .auth0-lock-terms {
    font-family: "Axiforma-Medium", sans-serif;
    margin-bottom: 20px;
    padding: 12px 16px !important;
    font-size: 14px !important;
    background-color: #ddecff !important;
    border-radius: 4px;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #0d1f23 !important;
  }

  .auth0-lock-header-logo,
  .auth0-lock-header-bg-blur,
  .auth0-lock-header-bg-solid {
    display: none !important;
  }

  .auth0-lock-header-bg {
    background: transparent;
  }

  .auth0-lock-name {
    font-family: "Aeonik-Medium", sans-serif !important;
    margin-bottom: 25px;
    font-size: 32px !important;
    line-height: 40px !important;
    letter-spacing: -1px !important;
    font-weight: 500;
    color: @ink-100;
  }

  .auth0-lock-form {
    padding: 0 !important;

    div {
      &.auth-0-lock-pane-separator {
        padding-top: 16px !important;
      }
    }

    p {
      margin-bottom: 8px !important;

      span {
        font-family: "Helvetica Neue", Arial, sans-serif;
        font-style: normal;
        font-weight: 510;
        font-size: 14px;
        line-height: 22px;
        color: #4c5a5d;
      }
    }

    .auth-lock-social-buttons-pane {
      .auth0-lock-social-buttons-container {
        display: flex;
        margin-top: 16px;

        &:before {
          content: "Log in with";
          position: absolute;
          top: -35px;
          left: 41%;
          font-family: "Helvetica Neue", Arial, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #4c5a5d;
        }

        .auth0-lock-social-button {
          font-family: "SF Pro", sans-serif;
          font-style: normal;
          font-weight: 510;
          background: #ffffff !important;
          border: 1px solid #0d1f23 !important;
          border-radius: 30px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 16px;
          margin: 0 8px;
          color: @ink-100;

          &.auth0-lock-social-big-button {
            margin: 0;
            height: 48px;

            &:first-of-type {
              margin-right: 8px;
            }

            &:hover:not([disabled]) {
              .auth0-lock-social-button-text {
                background-color: @grey-5!important;
              }
            }
          }

          .auth0-lock-social-button-text {
            color: @ink-100!important;
            font-size: 18px !important;
            line-height: 48px !important;
            text-overflow: clip !important;
            font-weight: 510 !important;
          }

          &:hover {
            background: @grey-5 !important;
          }

          .auth0-lock-social-button-icon {
            height: 46px !important;
            left: 8px;
          }
        }

        .auth0-lock-social-button[data-provider^="windows"],
        .auth0-lock-social-button[data-provider^="windowslive"] {
          .auth0-lock-social-button-text {
            font-size: 0 !important;

            &:before {
              content: "Microsoft";
              font-size: 18px;
            }
          }
          .auth0-lock-social-button-icon {
            background-image: url("../../../wwwroot/fe/img/microsoft-sso-icon.svg");
          }
        }
      }
    }

    .auth0-lock-error {
      .auth0-lock-input-wrap {
        border-color: #ffb1c4 !important;

        .auth0-lock-input {
          border: transparent !important;
        }
      }
    }

    .auth0-lock-error-msg {
      color: #eb597b;
    }
  }

  .auth0-lock-back-button {
    position: fixed !important;
    top: 105% !important;
    left: 30% !important;
    width: 150px !important;
    box-shadow: none !important;

    svg {
      position: relative;
      left: -85px;
      width: 16px;
      top: -20px;
      filter: invert(25%) sepia(96%) saturate(1337%) hue-rotate(190deg)
        brightness(94%) contrast(110%);
    }

    &:before {
      content: "Go back to Log in";
      background: url("../../../wwwroot/fe/img/blue-arrow-left.svg") no-repeat;
      background-size: 100%;
      font-family: "Axiforma-Medium", sans-serif;
      font-size: 16px;
      line-height: 28px;
      color: #0052cb;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .auth0-lock-submit .auth0-loading-container .auth0-loading {
    top: -7px !important;
    left: 40% !important;
  }

  .auth0-lock-tabs-container {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .auth0-sso-notice-container {
    margin: -20px 0 40px 0 !important;
    font-style: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.04em !important;
    color: #4c5a5d !important;
  }
}

.auth0-lock-quiet {
  .auth0-lock-submit {
    width: 240px !important;
  }
}

.auth0-lock-with-tabs {
  .auth0-lock-social-buttons-container:before {
    display: none;
  }
}
